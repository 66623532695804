<template>
  <div class="product-container">
    <div class="bank-info">
      <p class="product-name">{{ product.name }}</p>
      <p class="product-description" v-if="product.description">
        {{ product.description }}
      </p>
    </div>
    <div class="product-conditions">
      <div style="min-width: 121px">
        <p class="m0 mb5">TAE</p>
        <p class="m0 mb5 product-tae">
          {{ product.rates.tae }}
        </p>
        <p
          v-for="(tin, index) in product.rates.tin"
          :key="index"
          class="product-tin m0 mb5"
        >
          <span v-if="index === 0">TIN </span>{{ tin }}
        </p>
      </div>
      <div>
        <p class="m0 mb5">Cuota desde</p>
        <p class="m0">
          <span class="product-payment">{{ product.rates.payment }}€</span>/mes
        </p>
      </div>
    </div>
    <div class="product-buttons">
      <button type="button" @click="$emit('open', $event)" class="primary-btn">
        Que me llamen
      </button>
    </div>
  </div>
</template>

<script>
import { toSpanishFormat } from '../composables/utils';

export default {
  name: 'ProductMock',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      toSpanishFormat
    };
  },
};
</script>

<style scoped lang="scss">
.product-container {
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  background-color: #f0f0ea;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
  margin: 20px;

  @media (min-width: 992px) {
    padding: 40px;
    grid-template-columns: 1fr 2fr 216px;
    margin: 0 0 30px 0 !important;
  }

  .bank-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bank-logo {
    max-width: 120px;
  }

  .product-name {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .product-description {
    font-weight: 500;
    font-size: 14px;
    margin-top: 0;
  }

  .product-years {
    font-size: 12px;
  }

  .product-conditions {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;

    div:first-child {
      text-align: right;
    }

    @media (min-width: 992px) {
      grid-gap: 20px;

      div:first-child {
        text-align: left;
      }

    //   div {
    //     margin: 0 auto;
    //   }
    }
  }

  .product-payment {
    font-size: 28px;
    font-weight: 600;
  }

  .product-tae {
    font-size: 28px;
    font-weight: 600;
  }

  .product-tin {
    font-size: 12px;

    @media (min-width: 992px) {
      font-size: 14px;
    }
  }

  .product-buttons {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: min-content;
    align-items: center;
    margin: auto 0;

    @media (min-width: 992px) {
      justify-content: center;
    }

    a,
    button {
      width: 100%;

      @media (min-width: 992px) {
        width: 216px !important;
      }
    }
  }
}
</style>
